<template>
  <div id="content" class="app-content">
    <validation-observer ref="variationForm">
      <b-container>
        <b-row>
          <b-col cols="10">
            <b-row>
              <b-col>
                <h1 class="page-header">{{ headingTitle }}</h1>
              </b-col>
              <div v-if="this.isEditPage && variation.id">
                <a :href="rootUrl + `/preview/` + variation.id" target="_blank" class="btn btn-info btn-sm text-white"> Preview Voucher </a>
                &nbsp;
                <a :href="rootUrl + `/preview/booking/` + variation.id" target="_blank" class="btn btn-dark btn-sm text-white"> Preview Booking </a>
              </div>
            </b-row>

            <hr />
            <form @submit.prevent="submitFormData">
              <h4><i class="far fa-heading fa-fw"></i> Pick a Supplier</h4>
              <b-card>
                <validation-provider vid="supplier" name="Supplier" v-slot="context" rules="required">
                  <multi-select
                    v-model="supplier"
                    placeholder="Pick a supplier"
                    track-by="booking_name"
                    label="booking_name"
                    :options="options"
                    @search-change="findSupplier"
                    @select="supplierSelected"
                  />

                  <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
                    {{ context.errors[0] }}
                  </small>
                </validation-provider>
              </b-card>

              <template v-if="supplier">
                <hr />
                <h4><i class="far fa-heat fa-fw"></i> Price</h4>
                <b-card>
                  <validation-provider vid="price" name="Price" v-slot="context" rules="required">
                    <b-form-group label="Price (in NOK)">
                      <b-form-input
                        type="number"
                        v-model="price"
                        :state="getValidationState(context)"
                        v-bind="context.ariaInput"
                        placeholder="e.g Hiking"
                      />

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider vid="commission" name="Commission" v-slot="context" rules="required">
                    <b-form-group
                      :description="`
                        We get: ${(price * (commission / 100)).toFixed(2)} nok, supplier get: ${
                        (price - price * (commission / 100)).toFixed(2)
                      } nok`"
                      label="Commission (%)"
                    >
                      <b-form-input
                        v-model="commission"
                        :state="getValidationState(context)"
                        v-bind="context.ariaInput"
                        placeholder="e.g 30"
                      />

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <div v-if="temporaryPrices.length">
                    <hr />
                    <h5>Temporary Prices</h5>
                    <ul>
                      <li v-for="(price, i) in temporaryPrices" :key="i">
                        {{ formatPrice(price) }}
                      </li>
                    </ul>
                  </div>

                  <b-row v-if="isEditPage" align-h="end">
                    <b-col cols="auto">
                      <b-button v-b-modal.newTemporaryPricing variant="primary"> Set temporary pricing </b-button>

                      <b-modal
                        title="Add Temporary Pricing"
                        id="newTemporaryPricing"
                        hide-footer
                        v-slot:default="{ close }"
                      >
                        <product-variation-temporary-pricing :variation="variation" @close="refresh(close)" />
                      </b-modal>
                    </b-col>
                  </b-row>
                </b-card>
              </template>

              <template v-if="supplier">
                  <div class="mt-3">
                      <h4><i class="far fa-heading fa-fw" /> Discount Price</h4>
                      <b-card>
                          <validation-provider vid="discount" name="Discount" v-slot="context" rules="required">
                              <b-form-group label="Discount">
                                  <b-form-input
                                      id="discount"
                                      v-model="discount"
                                      :state="getValidationState(context)"
                                      aria-describedby="discount"
                                      v-bind="context.ariaInput"
                                  />

                                  <b-form-invalid-feedback id="discount" v-bind="context.ariaMsg">
                                      {{ context.errors[0] }}
                                  </b-form-invalid-feedback>
                              </b-form-group>
                          </validation-provider>
                      </b-card>
                  </div>
              </template>
              <template v-if="supplier">
                <hr />
                <h4><i class="far fa-heat fa-fw"></i> Details</h4>
                <b-card>
                  <b-form-group label="Place">
                    <validation-provider vid="place" name="Place" v-slot="context" rules="required">
                      <multi-select
                        v-model="place"
                        placeholder="Place"
                        track-by="name"
                        label="name"
                        :options="placeOptions"
                        @search-change="findPlace"
                      />

                      <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
                        {{ context.errors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <validation-provider vid="variation_name" name="Variation Name" v-slot="context" rules="required">
                    <b-form-group label="Variation Name">
                      <b-form-input
                        v-model="variation_name"
                        :state="getValidationState(context)"
                        v-bind="context.ariaInput"
                        placeholder="e.g Hiking"
                      />

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider vid="unique_name_place" name="" v-slot="context" rules="required">
                    <div class="text-red" v-if="context.errors.length">
                        {{ context.errors[0] }}
                    </div>
                    <hr>
                  </validation-provider>

                  <validation-provider vid="status" name="Status" v-slot="context" rules="required">
                    <b-form-group label="Status">
                      <b-form-radio-group
                        v-model="status"
                        class="w-100"
                        button-variant="primary"
                        :options="statusOptions"
                        :state="getValidationState(context)"
                        v-bind="context.ariaInput"
                        buttons
                      />

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    vid="voucher_description_text"
                    name="Voucher Description Text"
                    v-slot="context"
                    rules="required"
                  >

                    <b-form-group label="Voucher Description Text">
                      <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
                        {{ context.errors[0] }}
                      </small>
                      <markdown-editor v-model="voucher_description_text" />
                      <b-form-text>{{ 500 - voucher_description_text.length }} / 500 characaters remaing</b-form-text>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider vid="coordinates" name="Coordinates" v-slot="context">
                    <b-form-group>
                      <label>
                        Coordinates <a v-if="linkToGoogleMaps" :href="linkToGoogleMaps" target="_blank">Link to Google maps</a>
                      </label>

                      <b-form-input
                        v-model="coordinates"
                        :state="getValidationState(context)"
                        v-bind="context.ariaInput"
                        placeholder="e.g "
                      />

                      <small v-if="linkToGoogleMaps">
                        On the Google Maps page, right click the city title and click the first element in the
                        dropdown (it should say two comma-separated numbers). These are the coordinates and they are already
                        copied into the clipboard - you can paste them now.
                      </small>

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider vid="participants" name="Participants" v-slot="context" rules="required">
                    <b-form-group label="Participants">
                      <b-form-input
                        type="number"
                        v-model="participants"
                        :state="getValidationState(context)"
                        v-bind="context.ariaInput"
                        placeholder="e.g 10"
                      />

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <hr />
                  <b-row>
                    <b-col>
                      <b-form-checkbox v-model="more_about_data" switch>
                        <strong>Edit about data</strong>
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <hr />
                  <template v-if="more_about_data">
                    <validation-provider
                      vid="participants_text"
                      name="Participants Text"
                      v-slot="context"
                      rules="required"
                    >
                      <b-form-group label="Participants Text">
                        <b-form-input
                          v-model="participants_text"
                          :state="getValidationState(context)"
                          v-bind="context.ariaInput"
                          placeholder="e.g wear hiking boots"
                        />

                        <b-form-invalid-feedback v-bind="context.ariaMsg">
                          {{ context.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider vid="when" name="When" v-slot="context" rules="required">
                      <b-form-group label="When">
                        <b-form-input
                          v-model="when"
                          :state="getValidationState(context)"
                          v-bind="context.ariaInput"
                          placeholder="e.g At sunrise"
                        />

                        <b-form-invalid-feedback v-bind="context.ariaMsg">
                          {{ context.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider vid="duration" name="Duration" v-slot="context" rules="required">
                      <b-form-group label="Duration">
                        <b-form-input
                          v-model="duration"
                          :state="getValidationState(context)"
                          v-bind="context.ariaInput"
                          placeholder="e.g 10 hours"
                        />

                        <b-form-invalid-feedback v-bind="context.ariaMsg">
                          {{ context.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider vid="requirements" name="Requirements" v-slot="context" rules="required">
                      <b-form-group label="Requirements">
                        <b-form-input
                          v-model="requirements"
                          :state="getValidationState(context)"
                          v-bind="context.ariaInput"
                          placeholder="e.g come with a physical report"
                        />

                        <b-form-invalid-feedback v-bind="context.ariaMsg">
                          {{ context.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider vid="min_age" name="Min Age" v-slot="context" rules="required">
                      <b-form-group label="Min Age">
                        <b-form-input
                          type="number"
                          v-model="min_age"
                          :state="getValidationState(context)"
                          v-bind="context.ariaInput"
                          placeholder="e.g 18"
                        />

                        <b-form-invalid-feedback v-bind="context.ariaMsg">
                          {{ context.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <router-link v-if="booking_type === 'date'" :to="{name: 'suppliers.bookings', params: {id: supplier.id}}" target="_blank">
                      Add/manage bookings
                    </router-link>
                    <hr />
                  </template>
                </b-card>
                <hr />
              </template>

              <template v-if="supplier">
                <h4><i class="far fa-heat fa-fw"></i> Booking Fields</h4>
                <b-card>
                  <validation-provider vid="booking_name" name="Name" v-slot="context" rules="required">
                    <b-form-group label="Name">
                      <b-form-input
                        v-model="booking_name"
                        :state="getValidationState(context)"
                        v-bind="context.ariaInput"
                        placeholder="e.g Hiking company"
                      />

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider vid="booking_phone" name="Phone" v-slot="context">
                    <b-form-group label="Phone">
                      <b-form-input
                        v-model="booking_phone"
                        :state="getValidationState(context)"
                        v-bind="context.ariaInput"
                        placeholder="e.g +47 438 71 200"
                      />

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider vid="booking_phone_time" name="Phone Time" v-slot="context">
                    <b-form-group label="Phone Time">
                      <b-form-input
                        v-model="booking_phone_time"
                        :state="getValidationState(context)"
                        v-bind="context.ariaInput"
                        placeholder="e.g 10:00am - 5:00pm"
                      />

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider vid="booking_email" name="Email" v-slot="context">
                    <b-form-group label="Email">
                      <b-form-input
                        type="text"
                        v-model="booking_email"
                        :state="getValidationState(context)"
                        v-bind="context.ariaInput"
                        placeholder="e.g hiking@company.no"
                      />

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider vid="booking_url" name="Booking URL" v-slot="context">
                    <b-form-group label="Booking URL">
                      <b-form-input
                        type="url"
                        v-model="booking_url"
                        :state="getValidationState(context)"
                        v-bind="context.ariaInput"
                        placeholder="e.g https://booking.company.no"
                      />

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider vid="booking_website_url" name="Website URL" v-slot="context">
                    <b-form-group label="Website URL">
                      <b-form-input
                        type="url"
                        v-model="booking_website_url"
                        :state="getValidationState(context)"
                        v-bind="context.ariaInput"
                        placeholder="e.g https://company.no"
                      />

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider vid="booking_address" name="Booking adress" v-slot="context">
                    <b-form-group label="Booking adress">
                      <b-form-input
                        type="text"
                        v-model="booking_address"
                        :state="getValidationState(context)"
                        v-bind="context.ariaInput"
                        placeholder="e.g Storgata 39"
                      />

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider vid="booking_city" name="Booking city" v-slot="context">
                    <b-form-group label="Booking city">
                      <b-form-input
                        type="text"
                        v-model="booking_city"
                        :state="getValidationState(context)"
                        v-bind="context.ariaInput"
                        placeholder="e.g Oslo"
                      />

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider vid="booking_postal_code" name="Postal Code" v-slot="context">
                    <b-form-group label="Postal Code">
                      <b-form-input
                        type="number"
                        v-model="booking_postal_code"
                        :state="getValidationState(context)"
                        v-bind="context.ariaInput"
                        placeholder="e.g 1234"
                      />

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <hr>
                  <template>
                    <validation-provider vid="booking_type" name="Booking Type" v-slot="context" rules="required">
                      <b-form-group>
                        <b-form-radio-group
                            v-model="booking_type"
                            :options="bookingTypeOptions"
                            v-bind="context.ariaInput"
                            button-variant="primary"
                            track-by="booking_type"
                            buttons
                        />

                          <div class="text-red" v-if="context.errors.length">
                              {{ context.errors[0] }}
                          </div>
                      </b-form-group>
                    </validation-provider>

                      <div v-if="booking_type === 'standard'">
                          <b-col sm="auto">
                              <validation-provider name="Standard Booking Info" rules="required">
                                  <b-form-group label="Standard Booking Info">
                                      <froala v-model="standard_booking_info" />
                                  </b-form-group>
                              </validation-provider>
                          </b-col>
                      </div>

                      <div v-if="booking_type === 'date'">
                          <b-form-group label="Dates Booking Info">
                              <froala v-model="booking_info" />
                          </b-form-group>

                          <router-link
                              :to="{name: 'suppliers.bookings', params: {id: supplier.id}}"
                              target="_blank"
                          >
                              Add/manage bookings
                          </router-link>
                      </div>
                    <hr />

                    <template v-if="booking_type === 'csv'">
                      <b-col sm="auto">
                        <b-button v-b-modal.newCSVCodeBatch variant="primary"> Add codes </b-button>

                        <b-modal
                          title="New Code Batch"
                          id="newCSVCodeBatch"
                          hide-footer
                          v-slot:default="{ close }"
                          :no-close-on-backdrop="true"
                        >
                          <product-variation-c-s-v-codes-batch-form :variation="variation" @close="refresh(close)" />
                        </b-modal>

                        <hr />
                        <validation-provider name="Booking CSV Info" rules="required">
                          <b-form-group label="CSV Booking Info">
                            <froala v-model="booking_csv_info" />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </template>
                    </template>
                </b-card>
                <hr />
              </template>

              <template v-if="supplier">
                <h4><i class="far fa-heat fa-fw"></i> Gallery &amp; Content</h4>
                <router-link v-if="supplier" :to="{ name: 'supplier.gallery', params: { supplier: supplier.id } }"
                  >Manage galleries</router-link
                >
                <b-card>
                  <b-form-group label="Custom content">
                    <b-form-select v-model="custom_content_id" :options="customContentOptions" />
                  </b-form-group>

                  <b-form-group label="Supplier Galleries">
                    <b-form-select v-model="supplier_gallery_id" :options="galleryOptions" />
                  </b-form-group>
                </b-card>
              </template>

              <hr />
              <b-row align-h="end">
                <b-col cols="auto">
                  <b-button variant="primary" type="submit" :disabled="isLoading">
                    {{ isEditPage ? 'Update' : 'Create' }} Variation
                    <b-spinner v-show="isLoading" class="ml-2" small label="Loading" />
                  </b-button>
                </b-col>
              </b-row>
            </form>
          </b-col>
        </b-row>
      </b-container>
    </validation-observer>
  </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import MultiSelect from 'vue-multiselect'
import { products, suppliers, location } from '@/api'
import { getValidationState } from '../page-helpers'
import ProductVariationTemporaryPricing from './ProductVariationTemporaryPricing.vue'
import MarkdownEditor from '../../components/MarkdownEditor.vue'
import Froala from '@/components/Froala.vue'
import ProductVariationCSVCodesBatchForm from './ProductVariationCSVCodesBatchForm.vue'

export default {
  name: 'ProductsVariationForm',
  components: {
    MultiSelect,
    MarkdownEditor,
    ProductVariationTemporaryPricing,
    Froala,
    ProductVariationCSVCodesBatchForm,
  },
  data() {
    return {
      supplier: '',
      options: [],
      placeOptions: [],
      customContentOptions: [],
      galleryOptions: [],

      /** Details */
      variation_name: '',
      status: 2,
      coordinates: '',
      price: 100,
      commission: 30,
      participants: 10,
      participants_text: '',
      when: '',
      duration: '',
      requirements: '',
      min_age: 3,
      voucher_description_text: '',
      place: '',
      discount: '',

      /** Booking data */
      booking_name: '',
      booking_phone: '',
      booking_phone_time: '',
      booking_email: '',
      booking_url: '',
      booking_website_url: '',
      booking_info: '',
      standard_booking_info: '',
      booking_postal_code: '',
      booking_address: '',
      booking_city: '',
      booking_type: false,
      booking_csv_info: false,
      rootUrl: process.env.VUE_APP_API_URL,

      // galleries and content
      supplier_gallery_id: null,
      custom_content_id: null,

      // variation
      variation: { price_list: [] },
      more_about_data: false,
      isLoading: false,
    }
  },
  computed: {
    isEditPage() {
      return this.$route.name === 'product.variations.edit'
    },
    headingTitle() {
      return this.isEditPage ? 'Edit Variation' : 'New Variation'
    },
    statusOptions() {
      return [
        { text: 'In-active', value: 0 },
        { text: 'Bookable', value: 2 },
        { text: 'Active', value: 1 },
      ]
    },
    bookingTypeOptions() {
      return [
        { text: 'Standard booking', value: 'standard' },
        { text: 'Enable CSV booking', value: 'csv' },
        { text: 'Enable booking dates', value: 'date' },
      ]
    },
    temporaryPrices() {
      return this.variation?.price_list.filter(({ expires_at, expired }) => expires_at?.length && !expired)
    },
    linkToGoogleMaps() {
      if (!this.place || this.place?.coordinates) {
        return null;
      }

      return `https://www.google.com/maps/search/?api=1&query=${this.place.name.replace(' ', '+')}`
    }
  },
  beforeRouteEnter(from, to, next) {
    next((vm) => vm.preloadContent())
  },
  mounted() {
    this.preloadContent()
  },
  methods: {
    getValidationState,
    async preloadContent() {
      this.options = (await suppliers.all()).data()
      this.placeOptions = (await location.cities.paginated()).data()

      if (!this.isEditPage) return

      const variation = await products.variations.find(this.$route.params.variation)
      this.variation = variation.data
      this.supplier = this.variation.supplier
      this.place = this.variation.place
      this.price = this.variation.price.current.amount / 100
      this.commission = this.variation.price.commission
      this.booking_name = this.variation.booking_name
      this.booking_phone = this.variation.booking_phone
      this.booking_phone_time = this.variation.booking_phone_time
      this.booking_email = this.variation.booking_email
      this.booking_url = this.variation.booking_url
      this.booking_website_url = this.variation.booking_website_url
      this.booking_info = this.variation.booking_info
      this.standard_booking_info = this.variation.standard_booking_info
      this.booking_postal_code = this.variation.booking_postal_code
      this.booking_city = this.variation.booking_city
      this.booking_address = this.variation.booking_address
      this.discount = this.variation.discount

      this.variation_name = this.variation.variation_name
      this.status = this.variation.status
      this.coordinates = this.variation.coordinates
      this.participants = this.variation.participants
      this.participants_text = this.variation.participants_text
      this.when = this.variation.when
      this.duration = this.variation.duration
      this.requirements = this.variation.requirements
      this.min_age = this.variation.min_age
      this.booking_type = this.variation.booking_type
      this.booking_csv_info = this.variation.booking_csv_info
      this.voucher_description_text = this.variation.voucher_description_text
        ? this.variation.voucher_description_text
        : ''
      this.supplier_gallery_id = this.variation.supplier_gallery_id
      this.custom_content_id = this.variation.custom_content_id
      this.loadSupplierContentGalleries()
    },
    async findSupplier(booking_name) {
      const response = await suppliers.all({ filter: { booking_name } })
      const options = await response.data()

      this.options = options
    },
    async supplierSelected(supplier) {
      this.supplier = supplier
      this.booking_name = supplier.booking_name
      this.booking_phone = supplier.booking_phone
      this.booking_phone_time = supplier.booking_phone_time
      this.booking_email = supplier.booking_email
      this.booking_url = supplier.booking_url
      this.booking_website_url = supplier.booking_website_url
      this.booking_info = supplier.booking_info
      this.standard_booking_info = supplier.standard_booking_info
      this.booking_postal_code = supplier.booking_postal_code

      this.loadSupplierContentGalleries()
    },
    loadSupplierContentGalleries() {
      Promise.all([suppliers.customContent.all(this.supplier.id), suppliers.gallery(this.supplier.id).all()]).then(
        ([customContent, galleries]) => {
          /* Map custom content */
          this.customContentOptions = [{ value: null, text: 'Pick a content' }].concat(
            customContent.data().map((content) => ({ value: content.id, text: content.title }))
          )

          /* Map galleries */
          this.galleryOptions = [{ value: null, text: 'Pick a Gallery' }].concat(
            galleries.data.map((gallery) => ({
              value: gallery.id,
              text: gallery.title,
            }))
          )
        }
      )
    },
    async findPlace(name) {
      const response = await location.cities.paginated({ filter: { name: name } })
      const options = await response.data()

      this.placeOptions = [...new Set(options)]
    },
    async submitFormData() {
      this.isLoading = true;

      const data = {
        supplier_id: this.supplier.id,
        variation_name: this.variation_name,
        status: this.status,
        coordinates: this.coordinates,
        place: this.place.id,
        price: this.price,
        commission: this.commission,
        participants: this.participants,
        participants_text: this.participants_text,
        when: this.when,
        duration: this.duration,
        requirements: this.requirements,
        min_age: this.min_age,
        voucher_description_text: this.voucher_description_text,
        booking_name: this.booking_name,
        booking_phone: this.booking_phone,
        booking_phone_time: this.booking_phone_time,
        booking_email: this.booking_email,
        booking_url: this.booking_url,
        booking_website_url: this.booking_website_url,
        booking_info: this.booking_info,
        standard_booking_info: this.standard_booking_info,
        booking_postal_code: this.booking_postal_code,
        booking_address: this.booking_address,
        booking_city: this.booking_city,
        custom_content_id: this.custom_content_id,
        supplier_gallery_id: this.supplier_gallery_id,
        booking_type: this.booking_type,
        booking_csv_info: this.booking_csv_info,
        discount: this.discount,
      }

      if (this.isEditPage) {
        try {
          await products.variations.update(this.variation.id, data)
          this.$bvToast.toast(`Variation "${this.variation_name}" updated.`, {
            title: 'Variation updated',
            variant: 'primary',
          })
        } catch (error) {
          this.$refs.variationForm.setErrors(error.errors)
        }
      } else {
        try {
          const variation = await products.variations.create(this.$route.params.id, data)
          this.$router.push({ name: 'product.variations.edit', params: { variation: variation.data.id } })
          this.$bvToast.toast(`Variation "${this.variation_name}" created.`, {
            title: 'Variation created',
            variant: 'primary',
          })
        } catch (error) {
          this.$refs.variationForm.setErrors(error.errors)
        }
      }

      this.isLoading = false;
    },
    refresh(callback) {
      this.preloadContent()
      callback()
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat('nb-NO', {
        style: 'currency',
        currency: price.current.currency,
      })

      return formatter.format(price.current.amount / 100)
    },
  },
}
</script>
